import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import ClipLoader from "react-spinners/ClipLoader";
import { useTranslation } from "next-i18next";
import Slider from "react-slick";
import { Player } from 'video-react';
import cookies from 'next-cookies';
import Link from "next/link";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import MainLayout from "../Layouts/MainLayout";
import CommentItem from "../components/Comments/CommentItem";
// import ReactHlsPlayer from 'react-hls-player';
import Category from '../services/api/posts';
import styles from "../styles/CommentPage.module.scss";
import { post_data } from "../store/actions/posts";
import hooks from "../hooks/postHooks";
import authApi, { setApiAuthorizationHeader } from "../services/authinstance";
import keys from "../services/keys";
import translate_word from "../utils/translate";
import { posts_array } from "../store/actions/posts";
import { wrapper } from '../store';
import AvatarForPost from '../components/Comments/AvatarForPost';
import PostCommentSlider from '../components/PostCommenSlider';
const queryString = require('query-string');
const DynamicAvatar = dynamic(() => import('../components/Comments/Avatar'), {
    ssr: false
});
const CommentsData = dynamic(() => import('../components/Comments/CommentsData'), {
    ssr: false
});
const CommentsInput = dynamic(() => import('../components/Comments/CommentsInput'), {
    ssr: false
});
var canUseDOM = !!(
    (typeof window !== 'undefined' &&
        window.document && window.document.createElement)
);
export const getServerSideProps = wrapper.getServerSideProps(async (ctx) => {
    const { token } = cookies(ctx);
    const fbclid = ctx.query.fbclid ? ctx.query.fbclid : null;
    try {
        const response = await authApi.get(`${keys.SERVER_URL}/timeline/post/${ctx.query.id}/`);
        await ctx.store.dispatch(post_data({ ...response.data, reviews: response?.data?.comments, comment: {} }));
    } catch (error) {
        console.log(error);
    }

    return {
        props: {
            ...(await serverSideTranslations(ctx.locale, ["common"])),
            token: token || '',
            loggedIn: true,
            fbclid,
            post_id: ctx.query.id,
            language: ctx.locale
        }
    };
});
function PostPage(props) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: false,
                }
            }
        ]
    };
    const url = canUseDOM ? window.location.href : "";
    const { post,
        main_image,
        posts_loader,
        set_post_comment,
        set_child_comment,
        add_comment,
        add_child_comment,
        hide_post,
        report_post,
        get_comments,
        posts_like_dislike,
        likeCount,
        like_dislike,
        longPressEvent,
        user,
        isClick,
    } = hooks(props);
    const { t, i18n } = useTranslation();
    // const { comments, seeMore, setSeeMore, get_comments, posts_comment_loader, set_post_comment, add_comment, posts_like_dislike, like_dislike } = hooks(props)
    return (
        <MainLayout
            meta={`${post?.text} ${post?.city} ${post?.zip_code}`}
            title={`${translate_word(post?.service_details, i18n.language)} ${post?.city} ${post?.zip_code}`}
            hidden={props.fbclid ? true : false}
            page_description={`${post?.text} ${post?.city} ${post?.zip_code}`}
            page_title={`${translate_word(post?.service_details, i18n.language)} ${post?.city} ${post?.zip_code}`}
            image={main_image() ? main_image().file_url : ""}
            page_url={url}
            token={props.token}
            language={props.language}
            user_image={user ? user?.image : ""}
            id={user ? user?.id : ""}>
            <div className="container home-page-id">
                <main className={styles.comment_page}>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link href={`/${props?.language}`}><a>{t("main")}</a></Link></li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <Link href={`/${props?.language}/${post ? post?.id : ""}`}>
                                    <a>{`${post?.user_details?.first_name} ${post?.user_details?.last_name}`}</a>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{`${translate_word(post?.service_details, i18n.language)}`}</li>
                        </ol>
                    </nav>
				        
                    {!post.loading && post?.user_details ? <div className={styles.comment_data}>
                           <div className={styles.comments_cont}>
					       <AvatarForPost
                            language={props.language}
                            id={post?.user_details?.id}
                            hide_post={() => hide_post({ id: post?.id, token: props.token })}
                            report_post={() => report_post({ post: post?.id, user: user?.id, token: props.token, text: null })}
                            url={url}
                            image={post?.user_details?.image}
                            first_name={post?.user_details?.first_name}
                            last_name={post?.user_details?.last_name}
                            profession={translate_word(post?.service_name, props.language)}
                            price={post?.price}
                            free_day={post?.free_day}
                            service={translate_word(post?.service_details, i18n.language)}
                            zip_code={post?.zip_code}
                            address={post?.address?.substr(0, post?.address?.lastIndexOf(","))}
                            service_id={post?.service_details.id}
                            city={post?.city}
                            />
						</div>
                        <div className="post-page-slider"  {...longPressEvent}>
                            {isClick && <div className='animated_heart'>
                                <img src='/output-onlinegiftools.gif' />
                            </div>}
                            <Slider {...settings}>
                                {post?.files?.map((item, index) => {
                                    return item.file_type === "image"
                                        ? <img alt={`${post?.text} ${post?.city} ${post?.zip_code}`} title={`${translate_word(post?.service_details, i18n.language)} ${post?.city} ${post?.zip_code}`} key={index} className={styles.work_slider} src={item?.file_url} />
                                        : <video
                                            controls                                          >
                                            <source src={`${item?.file_url}#t=0.001`} type="video/mp4"></source>
                                        </video>;

                                })}
                            </Slider>
                            <PostCommentSlider
                            order_count={post?.order_count}
                                array={post.reviews}
                            />
                        </div>
                        <CommentsData
                            onClick={() => {
                                posts_like_dislike()
                            }}
                            like_dislike={like_dislike}
                            disabled={props.fbclid ? true : false}
                            id={post?.id}
                            comment_count={post?.comment_count}
                            seen_count={post?.seen_count}
                            order_count={post?.order_count}
                            like_count={likeCount?likeCount:post?.like_count}
                            liked_by_me={post?.liked_by_me}
                            token={props.token}
                        />
                        <div className={styles.title}>
                        <Link as={`/${props.language}/master/${post.user_details.id}`} href={`/${props.language}/master/${post.user_details.id}`}>
                            <a className={`${styles.title} ${styles.text_name}`}>{post.user_details.first_name} {post.user_details.last_name}</a>
                        </Link>  {post?.text}</div>                     
					</div> : null}
           
                </main>
            </div>
        </MainLayout>
    );
}

export default PostPage;
