import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import {
    post_page_comment_loading,
    post_page_comment,
    posts_comment_array,
    posts_comment_array_saga,
    post_page_comment_input_value,
    post_page_child_comment_input_value,
    add_comment_saga,
    add_post_page_comment,
    hide_post_saga,
    report_post_saga,
    delete_post_data,
    add_view,
    like_dislike_saga
} from "../store/actions/posts";
import useLongPress from "./useLongPress";



const Posts = (props) => {
    const [like_dislike, setLikeOrDislike] = useState(null);
    const [likeCount, setLikeCount]=useState(0);
    const [isClick, setClick] = useState(false);
    const post = useSelector(state => state.postData);
    const user = useSelector(state => state.getme.data);
    const dispatch = useDispatch();
    const router = useRouter();
    const posts_loader = (data) => dispatch(
        posts_comment_array_saga({
            loading: post_page_comment_loading,
            cb: post_page_comment,
            token: data.token,
            offset: post.comments
                ? ((post.comments.results.length / 10) * 10) : "", post: data.id
        }))
    const get_comments = (data) => dispatch(
        posts_comment_array_saga({
            act_data: data,
            loading: post_page_comment_loading,
            cb: posts_comment_array,
            token: data.token,
            limit:100000,
            post:post.id
            // offset: (post?.comments && 10 < post?.comments?.results?.length < post?.comments?.count)
            //     ? ((post?.comments?.results?.length / 10) * 10) : "", post: data?.id
        }))
    const set_post_comment = (data) => dispatch(post_page_comment_input_value(data))
    const set_child_comment = (data) => dispatch(post_page_child_comment_input_value(data));
    const add_child_comment = (data) => {
        let id = data.id;
        return dispatch(
            add_comment_saga({
                cb: add_post_page_comment,
                // loading: add_comment_loading,
                token: data.token,
                comment: post.new_child_comment_value,
                post: post.id,
                user: user.id,
                parent: id
            }),
        )
    }
    const posts_like_dislike = () => {
        dispatch(
            like_dislike_saga({ token: props.token, id: post.id, cb:()=>{                                
                setLikeOrDislike(true);
                setClick(true);
                setLikeCount(likeCount?+likeCount+1:+post?.like_count+1);
            } }),
            
        );
    };
    const onLongPress = () => {
        // if (props.token) {
            posts_like_dislike()
        // }
    };
    const longPressEvent = useLongPress(onLongPress);
    const main_image = () => {
        let array = post.files ? post.files : [];
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element.is_main) {
                return element
            }
        }
    }
    const add_comment = (token) => dispatch(
        add_comment_saga({
            cb: add_post_page_comment,
            token,
            comment: post.new_comment_value,
            post: post.id,
            user: user.id,
        }),
    )
    const hide_post = (data) => dispatch(
        hide_post_saga({
            ...data
        }),
    )
    const report_post = (data) => {
        dispatch(
            report_post_saga(data)
        )
    }
    const delete_post_data_act = () => {
        dispatch(delete_post_data())
    };
    const translate = useSelector(state => state.translate);
    useEffect(() => {
        if (post?.id) {
            get_comments({ id: post?.id, comments_loading: true})
        }
    },[post?.id])
    useEffect(() => {
        // if (props.token) {
            dispatch(add_view({ token: props.token, id: props.post_id }))
            
        // }
        // setMybutton(document.getElementById("btn-back-to-top"))
        if (props.loggedIn) return // do nothing if the user is logged in
        router.push("/login");
    }, [props.loggedIn]);
    useEffect(() => {
        if (isClick) {
            const timeoutId = setTimeout(() => {
                setClick(false)
            }, 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [isClick]);
    return {
        post,
        isClick,
        main_image,
        posts_loader,
        set_post_comment,
        set_child_comment,
        add_comment,
        add_child_comment,
        hide_post,
        report_post,
        get_comments,
        posts_like_dislike,
        likeCount,
        like_dislike,
        longPressEvent,
        translate,
        user
    }
}
export default Posts;